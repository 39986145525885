@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap");

* {
  margin: 0;
  font-family: "Inter", sans-serif;
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
}

.App {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

.classCenter {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c0ce94d;
}

.grupoCenter {
  width: 50%;
  height: 80vh;
  display: flex;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}

.grupoHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ddd;
  padding: 1.5rem;
}

.grupoInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  /* padding: 1rem 0; */
}

.input-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.1rem;
}

.input-box input {
  margin: 0.6rem;
  padding: 0.8rem;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 1px 6px #0000001c;
}

.input-box input:hover {
  background-color: #eeeeee75;
}

.input-box input:focus-visible {
  outline: 1px solid #0c0ce94d;
}

.input-box label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000000c0;
}

/*
=====
HELPERS
=====
*/

.ha-screen-reader {
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip: var(--ha-screen-reader-clip, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
=====
RESET STYLES
=====
*/

.field__input {
  --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);

  background-color: transparent;
  border-radius: 0;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  /* font-family: inherit; */
  font-size: inherit;
}

.field__input:focus::-webkit-input-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

.field__input:focus::-moz-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

/*
=====
CORE STYLES
=====
*/

.field {
  --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
  --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
  --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);
  --uiFieldBorderColorActive: var(
    --fieldBorderColorActive,
    rgba(22, 22, 22, 1)
  );

  display: var(--fieldDisplay, inline-flex);
  position: relative;
  font-size: var(--fieldFontSize, 1rem);
}

.field__input {
  box-sizing: border-box;
  width: var(--fieldWidth, 100%);
  height: var(--fieldHeight, 3rem);
  padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight)
    var(--fieldPaddingBottom, 0.5rem) var(--uiFieldPaddingLeft);
  border-bottom: var(--uiFieldBorderWidth) solid
    var(--fieldBorderColor, rgba(0, 0, 0, 0.25));
}

.field__input:focus {
  outline: none;
}

.field__input::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__input:focus::-moz-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__label-wrap {
  box-sizing: border-box;
  pointer-events: none;
  cursor: text;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.field__label-wrap::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 0;
  opacity: 0;

  position: absolute;
  bottom: 0;
  left: 0;
}

.field__input:focus ~ .field__label-wrap::after {
  opacity: 1;
}

.field__label {
  position: absolute;
  left: var(--uiFieldPaddingLeft);
  top: calc(50% - 0.5em);

  line-height: 1;
  font-size: var(--fieldHintFontSize, inherit);

  transition: top 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15),
    opacity 0.2s ease-out, font-size 0.2s ease-out;
}

.field__input:focus ~ .field__label-wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label {
  --fieldHintFontSize: var(--fieldHintFontSizeFocused, 0.75rem);

  top: var(--fieldHintTopHover, 0.25rem);
}

/* 
effect 1
*/

.field_v1 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transition: opacity 0.2s ease-out;
}

/* 
effect 2
*/

.field_v2 .field__label-wrap {
  overflow: hidden;
}

.field_v2 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transform: translate3d(-105%, 0, 0);
  transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
}

.field_v2 .field__input:focus ~ .field__label-wrap::after {
  transform: translate3d(0, 0, 0);
  transition-delay: 0;
}

/*
effect 3
*/

.field_v3 .field__label-wrap::after {
  border: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

.field_v3 .field__input:focus ~ .field__label-wrap::after {
  height: 100%;
}

/*
=====
LEVEL 4. SETTINGS
=====
*/

.field {
  --fieldBorderColor: rgb(168, 0, 28, 0.2);
  --fieldBorderColorActive: rgb(168, 0, 28);
}

/*
=====
DEMO
=====
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Open Sans,
    Ubuntu, Fira Sans, Helvetica Neue, sans-serif;
  margin: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding: 1rem;

  display: grid;
  grid-gap: 30px;
}

.linktr {
  order: -1;
  padding: 1.75rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.5rem 1.25rem;
}

@media (min-width: 1024px) {
  .linktr {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);

  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
}
img {
  width: 100px;
  height: 100px;
}
