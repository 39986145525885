.versoes-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.versoes-table .p-datatable-header {
  font-weight: bold;
  font-size: 1.2em;
}

.versoes-table .p-datatable-tbody tr {
  transition: background-color 0.3s;
}

.versoes-table .p-datatable-tbody tr:hover {
  background-color: #f1f1f1;
}
